import React from "react";
import { Link } from "gatsby";

export default ({ location, link, isArticle, isCyFH }) => {
  return (
    <div className="breadcrumb">
      <div className="wrapper">
        <Link to="/">Αρχική / </Link>
        {isArticle && (
          <Link to="/news-and-events">Νέα &amp; Εκδηλώσεις / </Link>
        )}
        {isCyFH && <p>CyFH / </p>}
        <Link to={link}>{location}</Link>
      </div>
    </div>
  );
};
