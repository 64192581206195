import React from "react";
import { useStaticQuery } from "gatsby";
import { GoLinkExternal } from "react-icons/go";

import PageHeader from "../components/pageHeader";
import Layout from "../components/layout";
import SEO from "../components/seo";

const CyfhMaterial = ({ location }) => {
  const { cyfhMaterial } = useStaticQuery(
    graphql`
      query {
        cyfhMaterial: allContentfulCyFhInformationalMaterialPdFs {
          edges {
            node {
              title
              externalLink
            }
          }
        }
      }
    `
  );

  const pdfList = cyfhMaterial.edges.map(pdf => (
    <div className="single-pdf" key={pdf.node.externalLink}>
      <a href={pdf.node.externalLink} rel="noreferrer">
        {pdf.node.title} <GoLinkExternal />
      </a>
    </div>
  ));

  return (
    <Layout pageTitle="cyfh-material">
      <SEO pageTitle="Ενημερωτικό Υλικό" />
      <PageHeader
        pageTitle="ΕΝΗΜΕΡΩΤΙΚΟ ΥΛΙΚΟ"
        pageDescription="Βρείτε ενημερωτικό υλικό σε μορφή PDF"
        page="Ενημερωτικό Υλικό"
        location={location}
        isCyFH={true}
      />

      <div className="page-wrapper">{pdfList}</div>
    </Layout>
  );
};

export default CyfhMaterial;
